(function () {
  'use strict';

  class UnauthorizedCtrl {
    constructor() {
    }
  }

  angular
    .module('planosSolucoesModule')
    .controller('UnauthorizedCtrl', UnauthorizedCtrl);
}());
