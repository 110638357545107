(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.videoLibrary.playback', {
      url: 'playback?vfiltro=',
      views: {
        '': {
          templateUrl: 'relatorios/video-library/playback/playback.tpl.html',
          controller: 'PlaybackCtrl'
        }
      },
      data: {
        roles: [['VIDEO_PLAYBACK', 'DADOS_SENSIVEIS_VISUALIZAR']]
      }
    });
  }
  angular
    .module('relatorios.videoLibrary.playback')
    .config(pageConfig);
}());
