(function () {
  'use strict';

  /* global _ */
  function resumoPeriodo() {
    return {
      templateUrl: 'relatorios/resumo-periodo.tpl.html',
      controller: 'ResumoPeriodoCtrl',
      controllerAs: 'ctrl',
      restrict: 'E',
      scope: {
        titulo: '=',
        subtitulo: '=',
        duracao: '=',
        distancia: '=',
        consumoCombustivel: '=',
        mediaConsumo: '=',
        consumoEletrico: '=',
        mediaConsumoEletrico: '=',
        logradouroInicio: '=',
        logradouroFim: '=',
        conducoes: '=',
        tempoPorFaixaRpm: '=',
        onClickEvento: '=',
        onClickPosicao: '=',
        faixasDescida: '=',
        diferencaAltitude: '='
      }
    };
  }

  class ResumoPeriodoCtrl {
    constructor($scope, EventosEnum, $sce, $translate, Eventos, ServiceVfiltro, GoogleTagManagerUtils, $rootScope, MeasurementUnitConverterService) {
      this.scope = $scope;
      this.eventosEnum = EventosEnum;
      this.translate = $translate;
      this.sce = $sce;
      this.eventos = Eventos;
      this.serviceVfiltro = ServiceVfiltro;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      
      this.enableTooltipTitulo = false;
      this.enableTooltipSubtitulo = false;
      this.eventoSelecionado = '';
      this.trusted = {};
      this.events = [];

      this.buildEvents();
      this.eventosContendoCurva = this.translate.instant(this.eventosEnum.CURVA);
      this.descidaFaixa1 = this.translate.instant(this.eventos.DESCIDA_FAIXA1.tipo);
      this.descidaFaixa2 = this.translate.instant(this.eventos.DESCIDA_FAIXA2.tipo);
      this.scope.$watch('conducoes', conducoes => {
        this.agruparEventosDasConducoes(conducoes);
        this.agruparDescidasDasConducoes(conducoes);
      });
      this.rootScope = $rootScope;
      this.measurementUnitConverterService = MeasurementUnitConverterService;

      this.distanceUnit = this.measurementUnitConverterService.getDistanceUnit(this.rootScope.usuario.measurementUnits.distanceMeasurement);
      this.fuelUnit = this.measurementUnitConverterService.getFuelUnit(this.rootScope.usuario.measurementUnits.fuelMeasurement);
      this.fuelEfficiency = this.measurementUnitConverterService.getFuelEfficiencyUnit(this.rootScope.usuario.measurementUnits.fuelMeasurement);
    }

    buildEvents() {
      this.events = {
        [this.getTranslation(this.eventosEnum.EXCESSO_VELOCIDADE)]: this.buildEvent('trimble-speeding', 'duration'),
        [this.getTranslation(this.eventosEnum.ACELERACAO_BRUSCA)]: this.buildEvent('trimble-harsh-acceleration'),
        [this.getTranslation(this.eventosEnum.FRENAGEM_BRUSCA)]: this.buildEvent('trimble-harsh-braking'),
        [this.getTranslation(this.eventosEnum.CURVA_BRUSCA)]: this.buildEvent('trimble-sharp-turn'),
        [this.getTranslation(this.eventosEnum.BANGUELA)]: this.buildEvent('trimble-coasting', 'duration'),
        [this.getTranslation(this.eventosEnum.PARADO_LIGADO)]: this.buildEvent('trimble-idling', 'duration'),
        [this.getTranslation(this.eventosEnum.RPM_EXCESSIVO)]: this.buildEvent('trimble-rpm', 'duration'),
        [this.getTranslation(this.eventosEnum.EXCESSO_VELOCIDADE_VIA)]: this.buildEvent('trimble-speeding', 'duration'),
        [this.getTranslation(this.eventosEnum.SENSOR_ACIONADO)]: this.buildEvent('trimble-sensor-on', 'duration'),
        [this.getTranslation(this.eventosEnum.CAMERA_OBSTRUIDA)]: this.buildEvent('trimble-obstructed-camera'),
        [this.getTranslation(this.eventosEnum.SEM_CINTO)]: this.buildEvent('trimble-no-seatbelt'),
        [this.getTranslation(this.eventosEnum.BOCEJO)]: this.buildEvent('trimble-yawning'),
        [this.getTranslation(this.eventosEnum.MOTORISTA_AUSENTE)]: this.buildEvent('trimble-no-driver'),
        [this.getTranslation(this.eventosEnum.USO_CIGARRO)]: this.buildEvent('trimble-smoking'),
        [this.getTranslation(this.eventosEnum.DISTRACAO_MOTORISTA)]: this.buildEvent('trimble-distraction'),
        [this.getTranslation(this.eventosEnum.FADIGA_MOTORISTA)]: this.buildEvent('trimble-fatique'),
        [this.getTranslation(this.eventosEnum.MANUSEIO_CELULAR)]: this.buildEvent('trimble-cell-phone-usage'),
        [this.getTranslation(this.eventosEnum.DISTANCIA_PERIGOSA)]: this.buildEvent('trimble-distance-warning'),
        [this.getTranslation(this.eventosEnum.ULTRAPASSAGEM_ILEGAL)]: this.buildEvent('trimble-ultrapassagem-ilegal'),
        [this.getTranslation(this.eventosEnum.SEM_CONE)]: this.buildEvent('trimble-cone'),
        [this.getTranslation(this.eventosEnum.SEM_OCULOS)]: this.buildEvent('trimble-sem-oculos'),
        [this.getTranslation(this.eventosEnum.SEM_LUVAS)]: this.buildEvent('trimble-luva'),
        [this.getTranslation(this.eventosEnum.CARONA)]: this.buildEvent('trimble-carona'),
        [this.getTranslation(this.eventosEnum.FOLLOWING_DISTANCE)]: this.buildEvent('trimble-unit-selector1'),
        [this.getTranslation(this.eventosEnum.ROP_ENGINE)]: this.buildEvent('trimble-between_outline'),
        [this.getTranslation(this.eventosEnum.ROP_BRAKE)]: this.buildEvent('trimble-between_outline'),
        [this.getTranslation(this.eventosEnum.YC_ENGINE)]: this.buildEvent('trimble-advanced-instructions_outline'),
        [this.getTranslation(this.eventosEnum.YC_BRAKE)]: this.buildEvent('trimble-advanced-instructions_outline'),
        [this.getTranslation(this.eventosEnum.COLLISION_MITIGATION)]: this.buildEvent('trimble-collision-warning1'),
        [this.getTranslation(this.eventosEnum.HAPTIC_WARNING)]: this.buildEvent('trimble-traffic-cone_outline'),
        [this.getTranslation(this.eventosEnum.FCW_BRAKE_EXT_ACC_DEM)]: this.buildEvent('trimble-truck-warning-delay_outline')
       
      };
      this.eventsWithDatePosition = [
        this.eventosEnum.FRENAGEM_BRUSCA,
        this.eventosEnum.CURVA_BRUSCA,
        this.eventosEnum.ACELERACAO_BRUSCA,
        this.eventosEnum.CAMERA_OBSTRUIDA,
        this.eventosEnum.SEM_CINTO,
        this.eventosEnum.BOCEJO,
        this.eventosEnum.MOTORISTA_AUSENTE,
        this.eventosEnum.USO_CIGARRO,
        this.eventosEnum.DISTRACAO_MOTORISTA,
        this.eventosEnum.FADIGA_MOTORISTA,
        this.eventosEnum.MANUSEIO_CELULAR,
        this.eventosEnum.DISTANCIA_PERIGOSA,
        this.eventosEnum.ULTRAPASSAGEM_ILEGAL,
        this.eventosEnum.SEM_CONE,
        this.eventosEnum.SEM_OCULOS,
        this.eventosEnum.SEM_LUVAS,
        this.eventosEnum.CARONA,
        this.eventosEnum.FOLLOWING_DISTANCE,
        this.eventosEnum.ROP_ENGINE,
        this.eventosEnum.ROP_BRAKE,
        this.eventosEnum.YC_ENGINE,
        this.eventosEnum.YC_BRAKE,
        this.eventosEnum.COLLISION_MITIGATION,
        this.eventosEnum.HAPTIC_WARNING,
        this.eventosEnum.FCW_BRAKE_EXT_ACC_DEM
      ];
    }

    getTranslation(name) {
      return this.translate.instant(name).toUpperCase();
    }

    buildEvent(icon, valueType = 'quantity') {
      return {
        icon,
        valueType
      };
    }

    getTipoAlerta(alerta) {
      return this.eventos.getEvento(alerta).alerta;
    }

    agruparEventosDasConducoes(conducoes) {
      if (!conducoes) {
        return;
      }
      this.eventosAgrupados = _.groupBy(
        _.flatten(conducoes.map(conducao => conducao.posicoes)),
        p => p.descricao.includes(this.eventosContendoCurva) ?
          this.translate.instant(this.eventosEnum.CURVA_BRUSCA) :
          p.descricao
      );
      this.eventosAgrupados = Object.keys(this.eventosAgrupados).map(key => {
        return {
          nome: key,
          duracao: this.eventosAgrupados[key]
            .map(posicao => posicao.duracao)
            .reduce((acumulado, atual) => acumulado + atual),
          posicoes: this.eventosAgrupados[key]
        };
      })
      .filter(agrupamento => 
        agrupamento.posicoes.length > 0 && this.getEventByName(agrupamento.nome)
      );
    }

    getEventByName(name) {
      return this.events[name.toUpperCase()];
    }

    agruparDescidasDasConducoes(conducoes) {
      if (!conducoes) {
        return;
      }
      this.descidasAgrupadas = _.groupBy(
        _.flatten(conducoes.map(conducao => conducao.posicoes))
        .filter(p => p.descricao.includes(this.translate.instant(this.eventosEnum.DESCIDA))),
        p => p.evento
      );
      this.descidasAgrupadas = Object.keys(this.descidasAgrupadas).map(key => {
        return {
          nome: key,
          duracao: this.descidasAgrupadas[key]
            .map(posicao => posicao.miscInfo.segundosFreioPressionado)
            .reduce((acumulado, atual) => acumulado + atual),
          posicoes: this.descidasAgrupadas[key]
        };
      })
      .sort((a, b) => a.nome.localeCompare(b.nome))
      .filter(agrupamento => agrupamento.posicoes.length > 0);
    }

    getTextoFaixaDescida(nome) {
      if (nome === this.descidaFaixa1) {
        return this.translate.instant('ce.resumoPeriodo.faixa', {x: 1});
      } else if (nome === this.descidaFaixa2) {
        return this.translate.instant('ce.resumoPeriodo.faixa', {x: 2});
      }
      return this.translate.instant('ce.resumoPeriodo.faixa', {x: 3});
    }

    getTextoTempoFaixaDescida(nome) {
      if (nome === this.descidaFaixa1) {
        return this.translate.instant('ce.resumoPeriodo.ateXseg', {x: this.scope.faixasDescida.segundosFaixa1});
      } else if (nome === this.descidaFaixa2) {
        return this.translate.instant('ce.resumoPeriodo.ateXseg', {x: this.scope.faixasDescida.segundosFaixa2});
      }
      return this.translate.instant('ce.resumoPeriodo.maiorXseg', {x: this.scope.faixasDescida.segundosFaixa2});
    }

    isAnyOfTheseEvents(agrupamentoNome, events) {
      return events.map(event => this.translate.instant(event)).includes(agrupamentoNome);
    }

    tituloCustomizado(reticencias) {
      if (!angular.isArray(this.scope.titulo)) {
        return this.scope.titulo;
      }

      let titulo = _.uniq(this.scope.titulo);

      if (titulo.length > 1) {
        if (reticencias) {
          return titulo[0] + '...';
        }
        this.enableTooltipTitulo = true;
        return titulo.slice(1).join(', ');
      }
      this.enableTooltipTitulo = false;
      return titulo[0];
    }

    subtituloCustomizado(reticencias) {
      if (!angular.isArray(this.scope.subtitulo)) {
        return this.scope.subtitulo;
      }

      let subtitulo = _.uniq(this.scope.subtitulo);

      if (subtitulo.length > 1) {
        if (reticencias) {
          return subtitulo[0] + '...';
        }
        this.enableTooltipSubtitulo = true;
        return subtitulo.slice(1).join(', ');
      }
      this.enableTooltipSubtitulo = false;
      return subtitulo[0];
    }

    montaHorarioViagem() {
      if (angular.isDefined(this.scope.inicio) && angular.isDefined(this.scope.fim)) {
        return this.scope.inicio + ' - ' + this.scope.fim;
      }
    }

    getPopoverContent() {
      const link = 'http://portal.veltec.com.br',
          content = this.translate.instant('ce.resumoPeriodo.popOverContent', {linkPortalVeltec: link});
      return this.trusted[content] || (this.trusted[content] = this.sce.trustAsHtml(content));
    }

    onClick(agrupamento) {
      this.eventoSelecionado = this.eventoSelecionado === agrupamento.nome ? '' : agrupamento.nome;
      this.scope.onClickEvento(this.eventoSelecionado);
    }

    formatarVelocidadeMaximaBanguela(intensidade) {
      if (angular.isString(intensidade) && intensidade.length > 0) {
        return intensidade.match(/\d+/).toString();
      }
      return '';
    }

    formatarVelocidadeMinimaBanguela(intensidade) {
      if (angular.isString(intensidade) && intensidade.length > 0) {
        return intensidade.match(/\d+(?=\,)/).toString();
      }
      return '';
    }

    hasConsumo() {
      return angular.isDefined(this.scope.mediaConsumo);
    }

    deveExibirFaixasRpm() {
      return _.some(this.scope.tempoPorFaixaRpm, Boolean);
    }

    onVideoClick() {
      this.googleTagManagerUtils.sendEventClickGTM('bdv_bdm_detalhes_reproduzir_video_vfleets', this.serviceVfiltro.factoryVfiltro.user);
    }
  }

  angular
    .module('resumoPeriodoModule')
    .controller('ResumoPeriodoCtrl', ResumoPeriodoCtrl)
    .directive('resumoPeriodo', resumoPeriodo);
}());
