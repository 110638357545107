/* global document */
/* eslint-disable */
(function () {
  'use strict';

  class PlanosSolucoesCtrl {
    constructor(Authenticator, FeatureOuService, $state) {
      this.auth = Authenticator;
      this.FeatureOuService = FeatureOuService;
      this.state = $state;
      this.shouldRedirectToUnauthorizedNAPage = false;
      this.init();
    }

    init() {
      this.auth.getUser().then((user) => {
        const contratacaoPlanos = angular.element('vfwc-contratacao-planos')[0];
        if (contratacaoPlanos) {
          contratacaoPlanos.solucaoIds = user.plain().solucoes.map(solucao => solucao.solucaoId);
          contratacaoPlanos.uoId = user.plain().uo.id;
        }
        this.getEventsConfig(user.plain().uo.id);
      });
    }

    getEventsConfig(userUoId) {
      this.FeatureOuService.getEventsConfig(userUoId).then((eventNaConfig) => {
        this.shouldRedirectToUnauthorizedNAPage = eventNaConfig;
        if (this.shouldRedirectToUnauthorizedNAPage) {
          this.state.go('root.unauthorized');
        } 
      }).catch((error) => {
        console.error('Error loading featureOu config: ', error);
      });
    }
  }

  PlanosSolucoesCtrl.$inject = ['Authenticator', 'FeatureOuService', '$state'];

  angular
    .module('planosSolucoesModule', [
      'ui.router',
      'v3gAuthenticatorModule'
    ])
    .controller('PlanosSolucoesCtrl', PlanosSolucoesCtrl);
}());