(function () {
  'use strict';

  function pageConfig($stateProvider) {
      return $stateProvider.state('root.unauthorized', {
        url: '/unauthorized',
        views: {
          'content@': {
            templateUrl: 'unauthorized-na-user/unauthorized-na-user.tpl.html',
            controller: 'UnauthorizedCtrl',
            controllerAs: 'ctrl'
          }
        },
        data: {
          hideTitulo: true
        }
      });
    }

  angular
        .module('planosSolucoesModule')
        .config(pageConfig);
}());
