/* global document */
/* eslint-disable */
(function () {
  'use strict';

  const INFORMATIVO_KEY = 'informativo-key',
      PAGE_INFORMATIVO_DEFAULT = 'INDICADORES_DIRECAO_SEGURA';

  class InformativoCtrl {
    constructor($stateParams, Authenticator, $rootScope, $window, FeatureOuService, $state) {
      this.window = $window;
      this.stateParams = $stateParams;
      this.rootScope = $rootScope;
      this.FeatureOuService = FeatureOuService;
      this.state = $state;
      this.shouldRedirectToUnauthorizedNAPage = false;
      this.isLoadingDependencies = true;
      Authenticator.getUser().then((user) => {
        this.user = user;
        this.getEventsConfig(user.uo.id).then(() => {
          if (!this.shouldRedirectToUnauthorizedNAPage) {
            this.init();
          }
          this.isLoadingDependencies = false;
        }).catch((error) => {
          this.log.error('Error loading featureOu config: ', error);
          this.isLoadingDependencies = false;
        });
      });
    }

    init() {
      const informativoElement = this.getElement();
      if (informativoElement) {
        if (!this.stateParams.informativoKey) {
          informativoElement.informativoKey = this.getPageKeyInformativo();
          informativoElement.uoId = this.rootScope.usuario.uo.id;
        } else {
          informativoElement.informativoKey = this.stateParams.informativoKey;
          informativoElement.uoId = this.stateParams.uoId;
        }
        informativoElement.noPadding = false;

        this.setPageKeyInformativo(informativoElement.informativoKey);
      }
    }

    getEventsConfig(userUoId) {
      return this.FeatureOuService.getEventsConfig(userUoId).then((eventNAConfig) => {
        this.shouldRedirectToUnauthorizedNAPage = eventNAConfig;
        if (this.shouldRedirectToUnauthorizedNAPage) {
          this.state.go('root.unauthorized');
        }
      });
    }

    getElement() {
      return angular.element(document.getElementById('informativo-box'))[0];
    }

    getPageKeyInformativo() {
      let keyInformativo = this.window.localStorage.getItem(`${INFORMATIVO_KEY}-${this.rootScope.usuario.id}`);
      return keyInformativo || PAGE_INFORMATIVO_DEFAULT;
    }

    setPageKeyInformativo(pageKey) {
      this.window.localStorage.setItem(`${INFORMATIVO_KEY}-${this.rootScope.usuario.id}`, pageKey);
    }
  }

  InformativoCtrl.$inject = ['$stateParams', 'Authenticator', '$rootScope', '$window', 'FeatureOuService', '$state'];

  angular
    .module('informativoModule')
    .controller('InformativoCtrl', InformativoCtrl);
}());
/* eslint-enable */
