(function () {
  'use strict';

  class PlanosSolucoesNovoUsuarioCtrl {
    constructor($state, $window, $timeout) {
      this.state = $state;
      this.window = $window;
      this.timeout = $timeout;
      this.shouldDisplayUnauthorizedPage = false;
      this.init();
    }

    init() {
      const browserLanguage = this.window.navigator.language || this.window.navigator.userLanguage;
      if (browserLanguage.startsWith('en')) {
        this.handleRedirectionFromTFM();
      } else {
        this.handleNormalRedirectionFlow();
      }
    }

    handleRedirectionFromTFM() {
      this.shouldDisplayUnauthorizedPage = true;
    }

    handleNormalRedirectionFlow() {
      this.timeout(() => {
        const contratacaoPlanos = angular.element('vfwc-contratacao-planos')[0];
        if (contratacaoPlanos) {
          contratacaoPlanos.solucaoIds = [];
          contratacaoPlanos.uoId = 1;
          contratacaoPlanos.linkContatoFixo = 'https://veltec.atlassian.net/servicedesk/customer/portal/6/group/20/create/362';
        }
      }, 0);
    }
  }

  PlanosSolucoesNovoUsuarioCtrl.$inject = ['$state', '$window', '$timeout'];

  angular
    .module('planosSolucoesNovoUsuarioModule', ['ui.router'])
    .controller('PlanosSolucoesNovoUsuarioCtrl', PlanosSolucoesNovoUsuarioCtrl);
}());
