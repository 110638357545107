(function () {
  'use strict';

  angular
    .module('cadastros.devices')
    .config(config);

  function config($stateProvider) {
    $stateProvider.state('root.devices', {
      url: '/devices?vdevices=',
      views: {
        'content@': {
          templateUrl: 'cadastros/devices/devices.tpl.html',
          controller: 'DevicesCtrl'
        }
      },
      data: {
        roles: [['MODULO_VISUALIZAR']]
      }
    });
  }
}());
